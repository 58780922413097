import React, { useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import Divider from "@mui/material/Divider";

import { Button } from "gatsby-theme-material-ui";

import { navigate } from "gatsby";
import { useShoppingCart } from "use-shopping-cart";

import { addItemToCartEventGA } from "../../utils/ga-events";
import { addItemToCartEventFB } from "../../utils/fb-events.js";
import useStripeProducts from "./products/useStripeProducts.js";

export default function BrandSelect({
  productName,
  data,
  stripeProducts,
  page,
  ...props
}) {
  const [brand, setBrand] = useState("");
  const [type, setType] = useState("");
  const [hasError, setHasError] = useState(false);
  const [stripeItem, setStripeItem] = useState("");
  const [displayText, setDisplayText] = useState(false);
  const [compatibilityList, setCompatibilityList] = useState("");
  const [open, setOpen] = React.useState(false);
  const { addItem } = useShoppingCart();
  const [csc, setCSC] = useState(null);

  const getStripeItem = (brand) => {
    let stripeProduct = stripeProducts.find((item) => {
      return item.product_id === brand.product_id;
    });

    return stripeProduct;
  };

  const products = useStripeProducts();

  const getCSC = products.filter((product) => {
    return product && product.name && product.name.includes("CZC");
  });

  const CSC = getCSC[0];

  const getBStypeZ = products.filter((product) => {
    return product && product.name && product.name.includes("BSZ");
  });

  const BSTypeZ = getBStypeZ[0];

  const handleChange = (event) => {
    const value = event.target.value;

    setBrand(value);
    setHasError(false);
    setType(value);
    if (value === "Beam Splitter Type Z + CSC") {
      setCSC("1");
    } else {
      setCSC(null);
    }
  };

  const handleAddItem = () => {
    const stripeItem = getStripeItem(brand);

    if (csc === "1") {
      addItem(BSTypeZ);
      addItem(CSC);
    } else {
      addItem(stripeItem);
      addItemToCartEventGA(stripeItem, brand);
      addItemToCartEventFB(stripeItem, brand);
    }
  };

  const handleBackDrop = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    if (brand) {
      setHasError(false);

      handleAddItem();
      setTimeout(() => {
        navigate("/store/microrec/software/adapters/accessories", {});
      }, 400);
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (type) {
      let item = getStripeItem(brand);
      setStripeItem(item);
    }
  }, [brand]);

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12}>
          <Typography mb={2} mt={2}>
            Choose the model:
          </Typography>
        </Grid>
        <FormControl fullWidth error={hasError}>
          <InputLabel id="demo-simple-select-label" style={{ zIndex: "0" }}>
            Choose...
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={brand}
            label="Device Brand"
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {data.map((item) => {
              return <MenuItem value={item}>{item.brand}</MenuItem>;
            })}
            {productName === "Beam Splitter" && (
              <MenuItem value="Beam Splitter Type Z + CSC">
                Beam Splitter Type Z + CSC
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <Grid container>
          <a
            href={
              productName === "Beam Splitter"
                ? "/store/beamsplitter"
                : "/store/adapter"
            }
            target="_blank"
            style={{
              color: "#838383",
              fontWeight: "bold",
              textAlign: "left",
              textDecoration: "underline",
              marginTop: "1em",
            }}
          >
            Learn more
          </a>
        </Grid>
        <Grid item marginTop={2}>
          {" "}
          <Button
            onClick={handleClick}
            color="primary"
            variant="contained"
            endIcon={<AddShoppingCartOutlinedIcon />}
            sx={{ color: "white" }}
          >
            Add to Cart
          </Button>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </Grid>
    </>
  );
}
