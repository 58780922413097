import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Compatibility from "./compatibility";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#F2F2F2",
  borderRadius: "5px",
  boxShadow: 24,
  width: "95%",
};

const link = {
  color: "rgb(255 161 0)",
  fontWeight: "600",
  position: "relative",
  textTransform: "none",
  fontSize: "18px",
  padding: 0,
  marginLeft: "0.5em",
  textDecoration: "underline",
};

const button = {
  color: "#8F4FFF",
  fontWeight: "600",
};

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#8F4FFF",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function TransitionsModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "0.5em",
          padding: "10px",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{ fontWeight: "600", fontSize: "18px", textAlign: "center" }}
        >
          You might need a Beam Splitter or an adapter or none of those.{" "}
        </div>
        <Button onClick={handleOpen} style={link}>
          Check compatibility.
        </Button>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {" "}
            <DialogActions>
              <Button autoFocus onClick={handleClose} style={button}>
                CLOSE
              </Button>
            </DialogActions>
            <Compatibility />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
